import Vue from 'vue'
import i18n from '@/i18n/i18n.js'
// import {
//   Message,
// } from 'element-ui';

// 数据字典
export function getDicData(keys, callback) {
	Vue.prototype.$http
		.put(Vue.prototype.$urlConfig.HyDicQueryList, keys)
		.then(({
			data
		}) => {
			// console.log('查询数据字典，请求成功');
			// console.log(data);
			if (200 == data.code && data.data) {
				callback(data.data);
			} else {
				if (!data.msg) {
					data.msg = i18n.t('hytxs0000032');
				}
				Vue.prototype.$message.warning(data.msg);
			}
		})
		.catch(error => {
			console.log(error);
			// console.log('查询数据字典接口，请求失败');
			// Vue.prototype.$message.error(this.$t('tips.submitRequestError'));
			Vue.prototype.$message.error(i18n.t('hytxs0000033'));
		});
}